/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    AttachmentType,
    ColumnFilter,
    CorporationsDto,
    GeneralStatus,
    LocationsDto,
    PatientBillingType,
    PhoneType,
    PracticeAttachmentDto,
    PracticeAttachmentDtoListResponseModel,
    PracticeAttachmentDtoModelResponse,
    PracticeContactsDto,
    PracticeUsersDto,
    ProfilesDto,
    ProvidersDto,
    SortType,
    UserRole,
} from "./data-contracts";
import {ContentType, HttpClient, RequestParams} from "./http-client";
import Axios from "@/libs/axios";

export class PracticeAttachments<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags PracticeAttachments
     * @name LoadList
     * @request GET:/PracticeAttachments/Load
     */
    loadList = (
        query?: {
            /** @format int32 */
            Take?: number;
            /** @format int32 */
            Skip?: number;
            IncludeIds?: number[];
            "OrderBy.Field"?: string;
            "OrderBy.Type"?: SortType;
            SearchTerm?: string;
            ColumnFilters?: ColumnFilter[];
            Status?: GeneralStatus;
            /** @format int64 */
            PracticeId?: number;
            ForDropdown?: boolean;
        },
        params: RequestParams = {},
    ) =>
        this.request<PracticeAttachmentDtoListResponseModel, any>({
            path: `/PracticeAttachments/Load`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags PracticeAttachments
     * @name PostPracticeAttachments
     * @request POST:/PracticeAttachments/Add
     */
    postPost = (data: PracticeAttachmentDto, params: RequestParams = {}) =>
        this.request<PracticeAttachmentDtoModelResponse, any>({
            path: `/PracticeAttachments/Add`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags PracticeAttachments
     * @name UploadCreate
     * @request POST:/PracticeAttachments/Upload
     */
    uploadCreate = (
        data: {
            /** @format int32 */
            PracticeId?: number;

            Comment?: string;

            /** @format int32 */
            UploadedById?: number;
            /** @format date-time */
            DateCreated?: string;
            /** @format int64 */
            Id?: number;
            Status?: GeneralStatus;
            ContentType?: string;
            ContentDisposition?: string;
            Headers?: Record<string, string[]>;
            /** @format int64 */
            Length?: number;
            Name?: string;
            FileName?: string;
        },
        params: RequestParams = {},
    ) =>
        this.request<PracticeAttachmentDtoModelResponse, any>({
            path: `/PracticeAttachments/Upload`,
            method: "POST",
            body: data,
            type: ContentType.FormData,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags PracticeAttachments
     * @name DownloadList
     * @request GET:/PracticeAttachments/Download
     */
    downloadList = (
        query?: {
            /** @format int64 */
            id?: number;
        },
        params: RequestParams = {},
    ) => {
        if (!params.headers) params.headers = [];

        // set the Accept header to blob
        params.headers['Accept'] = 'application/octet-stream';

        return this.request<Blob, any>({
            path: `/PracticeAttachments/Download`,
            method: "GET",
            query: query,
            format: "blob",
            ...params,
        });
    }

    downloadList2(id: number) {

        return Axios({
            method: 'GET',
            responseType: 'blob', // Important
            url: '/PracticeAttachments/Download',
            params: {id: id}
        })
    }


    /**
     * No description
     *
     * @tags PracticeAttachments
     * @name GetByIdList
     * @request GET:/PracticeAttachments/GetById
     */
    getByIdList = (
        query?: {
            /** @format int64 */
            id?: number;
        },
        params: RequestParams = {},
    ) =>
        this.request<PracticeAttachmentDtoModelResponse, any>({
            path: `/PracticeAttachments/GetById`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags PracticeAttachments
     * @name DeleteDelete
     * @request DELETE:/PracticeAttachments/Delete
     */
    deleteDelete = (
        query?: {
            /** @format int64 */
            id?: number;
        },
        params: RequestParams = {},
    ) =>
        this.request<PracticeAttachmentDtoModelResponse, any>({
            path: `/PracticeAttachments/Delete`,
            method: "DELETE",
            query: query,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags PracticeAttachments
     * @name UpdateCreate
     * @request POST:/PracticeAttachments/Update
     */
    updateCreate = (data: PracticeAttachmentDto, params: RequestParams = {}) =>
        this.request<PracticeAttachmentDtoModelResponse, any>({
            path: `/PracticeAttachments/Update`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
}
